import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Stack, useMediaQuery } from "@mui/material";


import { openDrawer } from "../../store/reducers/menu";
import FixedSidebar from "./Sidebars/FixedSidebar/FixedSidebar";
import Menu from "../../components/Menu/Menu";
import { ToastContainer } from "react-toastify";


// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const dispatch = useDispatch();


  const sideBarLeftWidth = "260px";

  return (
    <>
        <FixedSidebar width={sideBarLeftWidth}>
        <Stack justifyContent={"space-between"} height={"100%"}>
          <Stack
            sx={{
              flexBasis: 0,
              flexGrow: 2,
              overflow: "auto",
              width:"100%"
            }}
          >

            <Menu />
          </Stack>

         
        </Stack>
      </FixedSidebar>
      <Stack
        direction={"row"}
        sx={{
          width: `calc(100% - ${sideBarLeftWidth}px)`,
          marginLeft: sideBarLeftWidth,
          height: "100%",
          minHeight: "100vh",
          "@media (max-width:1024px)": {
            marginLeft: 0, // Adjust for mobile devices
            width: "100%",
            flexDirection: "column",
          },

          "@media (max-width:1024px)": {
            marginLeft: 0, // Adjust for mobile devices
            width: "100%",
            flexDirection: "column",
          },
        }}
      >
       <Outlet />
      </Stack>

      <ToastContainer />

    </>
  );
};

export default MainLayout;

