import { useRoutes } from 'react-router-dom';

// project import
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import { useSelector } from 'react-redux';


// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const auth = useSelector(state => state.authReducer.auth)
  return useRoutes([MainRoutes(auth), LoginRoutes(auth)]);
}
