import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';


import { Provider as ReduxProvider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';


import App from './App';
import { store } from './store';
import reportWebVitals from './reportWebVitals';

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
const persistor = persistStore(store)

root.render(
  <ReduxProvider store={store}>
  <PersistGate persistor={persistor}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    </PersistGate>
  </ReduxProvider>
);


reportWebVitals();
