import { lazy } from "react";

// project import
import Loadable from "../components/Loader/Loadable.js";
import MainLayout from "../layout/MainLayout";
import { Navigate } from "react-router-dom";

// render - dashboard
const BlogManagement = Loadable(
  lazy(() => import("../pages/BlogManagement/BlogManagement.jsx"))
);
const CreateBlog = Loadable(
  lazy(() => import("../pages/BlogManagement/Blog/CreateBlog/CreateBlog.jsx"))
);

const Dashboard = Loadable(
  lazy(() => import("../pages/Dashboard/Dashboard.jsx"))
);

const ClientManagement = Loadable(
  lazy(() => import("../pages/ClientManagement/ClientManagement.jsx"))
);

const CaseStudy = Loadable(
  lazy(() => import("../pages/CaseStudy/CaseStudy.jsx"))
);

const CreateCaseStudy = Loadable(
  lazy(() => import("../pages/CaseStudy/CreateCaseStudy/CreateCaseStudy.jsx"))
);


// ==============================|| MAIN ROUTING ||============================== //

const RenderComponent = (component) => {
  return component;
};

const MainRoutes = (auth) => ({
  path: "/",
  element: auth ? <MainLayout /> : <Navigate to="/login" />,
  children: [
    {
      path: "/",
      element: RenderComponent(<Dashboard />),
    },
    {
      path: "articles",
      element: RenderComponent(<BlogManagement />),
    },
    {
      path: "articles/:tab",
      element: RenderComponent(<BlogManagement />),
    },

    {
      path: "blog-create",
      element: RenderComponent(<CreateBlog />),
    },
    {
      path: "case-study-create",
      element: RenderComponent(<CreateCaseStudy />),
    },

    {
      path: "case-study",
      element: RenderComponent(<CaseStudy />),
    },

    {
      path: "client-management",
      element: RenderComponent(<ClientManagement />),
    },
  ],
});

export default MainRoutes;
