import "./App.css";

import Routes from "./routes";
import { CssBaseline } from "@mui/material";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <>
      {/* Hello */}
      {/* <LayoutHandlerWrapper> */}
    
        <CssBaseline />

        <Routes />
  
      {/* </LayoutHandlerWrapper> */}
    </>
  );
}

export default App;
