import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";


import {  Link, useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import {signOut } from "../../store/actions";
import { Diversity2Outlined } from "@mui/icons-material";

export default function Menu({ menu }) {

  const dispatch=useDispatch();

  const navigate=useNavigate()
const handleLogout=()=>{
  dispatch(signOut(null))
  navigate("/login")
}

  const items=[
    {
      title:"Home",
      href:"/"
    },
    {
      title:"Blog Management",
      href:"/articles"
    },
    {
      title:"Case Study",
      href:"/case-study"
    },
    {
      title:"Client Management",
      href:"/client-management"
    }

    ,
    {
      title:"Logout",
      onClick:()=>handleLogout()
    }
  ]

  return (
    <List
      sx={{ width: "100%", maxWidth: "260px !important", bgcolor: "background.paper",display:"flex",flexDirection:"column" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {items &&
        items.map((item, index) => {
          if (item.href) {
            return (
              <Link
                key={JSON.stringify(item.title)}
                to={item.href}
              >
                <ListItemButton>
                  {/* <ListItemIcon color="red">{item.icon}</ListItemIcon> */}
                  <ListItemText primary={item.title} />
                </ListItemButton>
              </Link>
            );
          }

          if (item?.onClick ) {
            return (
              <Link
                key={JSON.stringify(item.title)}
                onClick={item?.onClick}
              >
                <ListItemButton>
                  {/* <ListItemIcon color="red">{item.icon}</ListItemIcon> */}
                  <ListItemText primary={item.title} />
                </ListItemButton>
              </Link>
            );
          }
        })}
    </List>
  );
}
