import { lazy } from "react";

// project import
import Loadable from "../components/Loader/Loadable.js";
import MinimalLayout from "../layout/MinimalLayout";
import { Navigate } from "react-router-dom";

// render - login
const Login = Loadable(
  lazy(() => import("../pages/Authentication/Login/Login.jsx"))
);

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = (auth) => ({
  path: "/",

  element: !auth ? <MinimalLayout /> : <Navigate to="/" />,
  children: [
    {
      path: "login",
      element: <Login />,
    },
  ],
});

export default LoginRoutes;
