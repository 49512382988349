// third-party
import { combineReducers } from 'redux';
// project import
import authReducer from "./auth";

import filterDataRedux from './filterData';

import menu from './menu';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({ menu, authReducer,filterDataRedux });

export default reducers;
