import React from "react";
import { Box, styled } from "@mui/material";

export default function FixedSidebar({ children, width = "310px" }) {

  return (
    <div
      style={{
        width: width,
        height: "100%",
        position: "fixed",
        top: 0,
        left: 0,
        overflowY: "auto",
        zIndex: 999,
      }}
    >
      <div
        style={{
          padding:"2rem",
        //   padding: theme.spacing(2),
          height: "100%",
        }}
      >
        {children}
      </div>
    </div>
  );
}
