import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => (
  <>
    <Outlet />
    <ToastContainer />

  </>
);

export default MinimalLayout;
