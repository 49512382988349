export const AUTH = "AUTH";

export const auth = (payload) => ({
  type: AUTH,
  payload,
});

export const SIGNOUT = "SIGNOUT";

export const signOut = () => ({
  type: SIGNOUT,
});
