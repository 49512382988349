import { SIGNOUT,AUTH } from "../actions";

const initialState={
    auth:null
}

export default function authReducer(state=initialState,action){

    switch(action.type){
        case AUTH:
            return{
                ...state,
                auth:action.payload
            }
        
        case SIGNOUT:
            return initialState;
        

        default:
            return state
    }

}