// third-party
import { configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage';

// project import
import reducers from './reducers';

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //


const persistConfig = {
  key: 'root',
  storage: sessionStorage,
  blacklist: ['signup', 'ThemeOptions', 'messages', 'billing']
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer
});



export { store };
