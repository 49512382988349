import { SET_FILTER_DATA } from "../actions";


const initialState = {
   data:null
};

export default function filterDataRedux(state = initialState, action) {
    switch (action.type) {
        case SET_FILTER_DATA:
            return {
                data: action.payload
            };
        default:
            return state;
    }
}